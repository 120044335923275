import React from 'react';
import outlook from '../img/autlook2019.png';

const FormLogin = ({onChange, onSubmit, carnet, password}) => (
        <form 
        onSubmit={onSubmit} 
        className='m-4'
        >
                        <div className="form-group text-white">
                            <label htmlFor="">Usuario</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            id="carnet" 
                            name="carnet" 
                            aria-describedby="" 
                            onChange={onChange} 
                            value={carnet}/>                            
                        </div>
                        <div className="form-group text-white">
                            <label htmlFor="">Contraseña</label>
                            <input 
                            type="password" 
                            className="form-control" 
                            id="password" 
                            name="password" 
                            onChange={onChange} 
                            value={password}/>
                        </div>
                        {/* <hr></hr> */}
                        {/* <div class="col-6 form-check">
                            <input class="form-check-input" type="radio" name="loginSource"  value="0" defaultChecked onChange={onChange} />
                            <label class="form-check-label" for="exampleRadios1">
                                Login con Registro
                            </label>
                        </div>
                        <div class="col-6 form-check">
                            <input class="form-check-input" type="radio" name="loginSource"  value="1" onChange={onChange} />
                            <label class="form-check-label" for="exampleRadios2">
                                Login con CCESEN
                            </label>
                        </div> */}
                        <hr></hr>
                        <button type="submit" 
                        className="btn btn-block btn-primary" 
                        > Enviar</button>
                        {/* <small id="emailHelp" className="form-text text-white">* No compartir sus credenciales de acceso con nadie</small> */}
                        <small id="emailHelp" className="form-text text-white">* Credenciales de acceso son las mismas que utilizas en Registro Académico</small>

                        <hr></hr>
                        {/* <a className='btn btn-info btn-block' href='https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?prompt=select_account&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fapi%2Fauth%2Fmicrosoft%2Fcallback&scope=User.Read&client_id=d36e2892-87b7-4bbf-afcd-21b6fe2105c4'>Ingresar con correo institucional</a> */}
                        <a className='btn btn-info btn-block' href='/api/auth/microsoft'>Ingresar con correo institucional <br></br>
                        <img src={outlook}/> 
                        </a>
        </form>
)

export default FormLogin